import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: 'es',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

i18n.addResourceBundle(
  'es',
  'common',
  {
    roleList: {
      admin: 'Administrador',
      user: 'Usuario',
    },

    status: {
      pending: 'Pendiente',
      sent: 'Enviado',
      scheduled: 'Programado',
      canceled: 'Anulado',
      paid: 'Pagado',
      overdue: 'Vencido',
      invoiced: 'Facturado',
      'commission-invoice-missing': 'Falta facturar comisión',
      'commission-payment-missing': 'Factura de comisión pendiente de pago',
    },

    roles: ['admin', 'user'],
    actives: 'Activos',
    inactives: 'Inactivos',

    actions: {
      delete: 'Eliminar',
      edit: 'Editar',
      duplicate: 'Duplicar',
      saveChanges: 'Guardar cambios',
      save: 'Guardar',
      send: 'Enviar',
      cancel: 'Cancelar',
      back: 'Volver',
      create: 'Crear',
      download: 'Descargar',
      reject: 'Rechazar',
      saveAndApprove: 'Guardar y aprobar',
      search: 'Buscar',
    },

    errorMessage: 'Ha ocurrido un error. Intente nuevamente',
  },
  true,
  false,
);

export default i18n;
