import { useTranslation } from 'react-i18next';
import DialogContainer from '../DialogContainer/DialogContainer';
import { FileData, SendEmailModalProps } from './types';
import { Grid, Button, Autocomplete, Chip, Typography, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { SendOutlined } from '@mui/icons-material';
import Select from '../Select/Select';
import { useSendEmailModal } from './useSendEmailModal';
import { SendEmailForm } from './schema';
import Input from '../Input/Input';
import './i18n';
import { AttachedFile, AttachedFilesContainer, AutocompleteListItem } from './styles';
import dayjs from 'dayjs';
import DateTimePicker from '../DateTimePicker/DateTimePicker';
import PdfIcon from '../../assets/images/pdf-icon.png';

const SendEmailModal = ({
  isOpen,
  senders,
  defaultBody,
  defaultSubject,
  defaultSender,
  defaultRecipients,
  defaultScheduled,
  suggestedContacts,
  filesData,
  onSend,
  onClose,
}: SendEmailModalProps) => {
  const { t } = useTranslation('sendEmailModal');
  const { form, handleReceivers } = useSendEmailModal({
    isOpen,
    defaultBody,
    defaultSubject,
    defaultSender,
    defaultRecipients,
    defaultScheduled,
    onSend,
  });

  const hasError = (field: keyof SendEmailForm) => !!form.errors[field] && form.submitCount > 0;

  const handleDownload = async (fileUrl: FileData['fileUrl']) => {
    if (fileUrl) window.open(fileUrl, '_blank');
  };

  return (
    <DialogContainer
      open={isOpen}
      onClose={onClose}
      title={t('title')}
      width={{ xs: '100%', sm: '70%', md: 760 }}
      footer={
        // TODO: Ver como quitar el segundo form duplicado y que funcione el boton para el onsubmit
        <form onSubmit={form.handleSubmit}>
          <Grid width={{ sm: '100%' }} display="flex" justifyContent="end">
            <Button type="submit" variant="contained" size="medium" sx={{ gap: 1 }}>
              <SendOutlined />
              {t('send')}
            </Button>
          </Grid>
        </form>
      }>
      <form onSubmit={form.handleSubmit}>
        <Grid container rowSpacing={1} columnSpacing={2} sx={{ mb: 1 }}>
          <Grid item xs={12}>
            <Select
              value={form.values.smtp || null}
              name="smtp"
              label={t('smtp.label')}
              options={senders.map(s => ({ id: s.id, name: `${s.name} <${s.smtpAddress}>` }))}
              onChange={value => form.setFieldValue('smtp', value)}
              error={hasError('smtp') ? t(`${form.errors.smtp}`) || '' : ''}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              id="to"
              onChange={(e, v) => handleReceivers(v)}
              value={form.values.to}
              options={suggestedContacts || []}
              freeSolo
              filterSelectedOptions
              renderTags={(value: readonly (unknown | string | undefined)[], getTagProps) =>
                value
                  .filter(v => v)
                  .map((contact: unknown | string | undefined, index: number) => (
                    <Chip label={(contact as string) || ''} {...getTagProps({ index })} />
                  ))
              }
              renderOption={(props, option) => {
                return (
                  <AutocompleteListItem {...props}>
                    <Typography>{option?.name}</Typography>
                    <Typography variant="body2" color="GrayText">
                      {option?.email}
                    </Typography>
                  </AutocompleteListItem>
                );
              }}
              getOptionLabel={opt => (!opt ? '' : typeof opt === 'string' ? opt : opt.name)}
              renderInput={params => (
                <Input
                  {...params}
                  label={t('to.label')}
                  error={hasError('to')}
                  helperText={hasError('to') ? t(`${form.errors.to}`) || '' : ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              label={t('subject.label')}
              name="subject"
              value={form.values.subject}
              onChange={form.handleChange}
              error={hasError('subject')}
              helperText={hasError('subject') ? t(`${form.errors.subject}`) || '' : ''}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              label={t('body.label')}
              name="body"
              value={form.values.body}
              onChange={form.handleChange}
              error={hasError('body')}
              helperText={hasError('body') ? t(`${form.errors.body}`) || '' : ''}
              fullWidth
              multiline
              rows={3}
            />
          </Grid>
          {filesData.some(({ fileUrl }) => fileUrl) && (
            <Grid item xs={12}>
              <Typography variant="subtitle1">{t('attachedFiles')}</Typography>
              <AttachedFilesContainer>
                {filesData.map(({ filename, fileUrl }) => (
                  <AttachedFile onClick={() => handleDownload(fileUrl)}>
                    <img src={PdfIcon} alt="icon" />
                    <Typography>{`${filename}.pdf`}</Typography>
                  </AttachedFile>
                ))}
              </AttachedFilesContainer>
            </Grid>
          )}
          <Grid item xs={12} display="flex" alignItems="center" sx={{ pt: '0 !important' }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox checked={form.values.isProgrammed} name="isProgrammed" onChange={form.handleChange} />
                }
                label={t('isProgrammed.label')}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12} sm={6}>
            {form.values.isProgrammed && (
              <DateTimePicker
                label={t('programDate.label')}
                sx={{ width: '100%' }}
                slotProps={{ textField: { variant: 'outlined' } }}
                onChange={e => form.setFieldValue('programDate', dayjs(e as string))}
                value={form.values.programDate}
                error={hasError('programDate') ? t(`${form.errors.programDate}`) || '' : ''}
              />
            )}
          </Grid>
        </Grid>
      </form>
    </DialogContainer>
  );
};

export default SendEmailModal;
