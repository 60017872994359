import React from 'react';
import { AppBar, Box, Dialog, Menu, Slide, Tab, Tabs, Toolbar, Typography, IconButton, Icon } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { DocumentHeaderActionType, DocumentViewerProps } from './types';
import { CloseOutlined, MoreVert } from '@mui/icons-material';
import {
  Container,
  PanelContainer,
  PdfContainer,
  TabContent,
  ActionsContainer,
  OutlinedIconButton,
  ExpandIndicator,
  ExpandIcon,
  TabContainer,
  PrimaryButton,
} from './styles';
import { Document, Page, pdfjs } from 'react-pdf';
import { MenuButton } from '../Table/components/RowAction/styles';
import { useDocumentViewer } from './useDocumentViewer';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DocumentViewer = ({ isOpen, tabs, pdfUrl, headerConfig, onClose }: DocumentViewerProps) => {
  const {
    isPanelOpen,
    isSmDown,
    currentTab,
    numberOfPages,
    anchorElem,
    pageWidth,
    handleContextMenu,
    handleCloseContextMenu,
    handleCurrentTab,
    onDocumentLoad,
    handleExpandPanel,
  } = useDocumentViewer();

  const showMoreActionButton =
    (headerConfig.secondaryActions && headerConfig.secondaryActions.length > 0) ||
    (isSmDown && headerConfig.primaryAction);

  const handleActionClick = (action: DocumentHeaderActionType) => () => {
    handleCloseContextMenu();
    action.onClick();
  };

  return (
    <Dialog fullScreen open={isOpen} onClose={onClose} TransitionComponent={Transition} disablePortal>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseOutlined />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" color="inherit">
            {headerConfig.title}
          </Typography>
          <ActionsContainer>
            {showMoreActionButton && (
              <>
                {isSmDown ? (
                  <IconButton color="inherit" onClick={handleContextMenu}>
                    <MoreVert />
                  </IconButton>
                ) : (
                  <OutlinedIconButton variant="contained" color="secondary" onClick={handleContextMenu}>
                    <MoreVert />
                  </OutlinedIconButton>
                )}
                <Menu
                  id="header-actions-menu"
                  anchorEl={anchorElem}
                  keepMounted
                  open={!!anchorElem}
                  onClose={handleCloseContextMenu}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  slotProps={{ paper: { style: { width: 160, right: 0 } } }}>
                  {isSmDown && headerConfig.primaryAction && (
                    <MenuButton key={99} onClick={handleActionClick(headerConfig.primaryAction)}>
                      <Icon color="inherit" baseClassName="material-icons-outlined" fontSize="small">
                        {headerConfig.primaryAction.icon}
                      </Icon>
                      <Typography noWrap>{headerConfig.primaryAction.label}</Typography>
                    </MenuButton>
                  )}
                  {headerConfig.secondaryActions?.map((action, index) => (
                    <MenuButton key={index} onClick={handleActionClick(action)}>
                      <Icon color="inherit" baseClassName="material-icons-outlined" fontSize="small">
                        {action.icon}
                      </Icon>
                      <Typography noWrap>{action.label}</Typography>
                    </MenuButton>
                  ))}
                </Menu>
                {headerConfig.primaryAction && !isSmDown && (
                  <PrimaryButton
                    variant="contained"
                    color="secondary"
                    sx={{ gap: 1 }}
                    onClick={headerConfig.primaryAction.onClick}>
                    <Icon color="inherit" baseClassName="material-icons-outlined" fontSize="small">
                      {headerConfig.primaryAction.icon}
                    </Icon>
                    {headerConfig.primaryAction.label}
                  </PrimaryButton>
                )}
              </>
            )}
          </ActionsContainer>
        </Toolbar>
      </AppBar>
      <Container>
        <PdfContainer>
          <Document file={pdfUrl} onLoadSuccess={onDocumentLoad}>
            {Array.from({ length: numberOfPages }, (_, i) => i + 1).map((page: number) => (
              <Page pageNumber={page} width={pageWidth} renderTextLayer={false} renderAnnotationLayer={false} />
            ))}
          </Document>
        </PdfContainer>
        {tabs && (
          <PanelContainer isOpen={isPanelOpen}>
            <ExpandIndicator>
              <ExpandIcon onClick={handleExpandPanel} />
            </ExpandIndicator>
            {tabs?.length > 1 && (
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={currentTab}
                  onChange={handleCurrentTab}
                  textColor="secondary"
                  indicatorColor="secondary"
                  variant="fullWidth">
                  {tabs.map((tab, index) => (
                    <Tab label={tab.label} key={index} />
                  ))}
                </Tabs>
              </Box>
            )}
            <TabContainer includeTabs={!!(tabs && tabs.length > 1)}>
              <TabContent>{tabs?.map((tab, index) => (index === currentTab ? tab.content : null))}</TabContent>
            </TabContainer>
          </PanelContainer>
        )}
      </Container>
    </Dialog>
  );
};

export default DocumentViewer;
